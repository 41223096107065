var player;
var videoPreview;
var done = false;

window.addEventListener('DOMContentLoaded', function () {
  videoPreview = document.getElementById('video-preview');

  // 2. This code loads the IFrame Player API code asynchronously.
  const scriptTag = document.createElement('script');

  scriptTag.src = 'https://www.youtube.com/iframe_api';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

  videoPreview.addEventListener('click', function () {
    player.playVideo();
    videoPreview.classList.add('hidden');
  });
});


function onPlayerStateChange(event) {
  if (event.data === window.YT.PlayerState.PAUSED) {
    // videoPreview.classList.remove('hidden');
    // player.seekTo(0);
  }
}

// eslint-disable-next-line no-unused-vars
function onYouTubeIframeAPIReady() {
  player = new window.YT.Player('video', {
    playerVars: {
      playsinline: 1,
    },
    events: {
      onStateChange: onPlayerStateChange,
    },
  });
}
